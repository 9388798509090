const _temp0 = require("./Ants.ts");
const _temp1 = require("./Bagel.ts");
const _temp2 = require("./Bezier.ts");
const _temp3 = require("./Circles.ts");
const _temp4 = require("./Drapes.ts");
const _temp5 = require("./Fish.ts");
const _temp6 = require("./Flow.ts");
const _temp7 = require("./Landscape.ts");
const _temp8 = require("./MorphingShapes.ts");
const _temp9 = require("./Playa.ts");
const _temp10 = require("./Schotter.ts");
const _temp11 = require("./Sight.ts");
const _temp12 = require("./Sort.ts");
const _temp13 = require("./Strata.ts");
module.exports = {
  "Ants": _temp0,
  "Bagel": _temp1,
  "Bezier": _temp2,
  "Circles": _temp3,
  "Drapes": _temp4,
  "Fish": _temp5,
  "Flow": _temp6,
  "Landscape": _temp7,
  "MorphingShapes": _temp8,
  "Playa": _temp9,
  "Schotter": _temp10,
  "Sight": _temp11,
  "Sort": _temp12,
  "Strata": _temp13
}