import { _ as _extends } from './sizes-501602a9.esm.js';
import components from '../components/dist/chakra-ui-theme-components.esm.js';
import foundations from '../foundations/dist/chakra-ui-theme-foundations.esm.js';
import { isObject } from '@chakra-ui/utils';
import '../foundations/spacing/dist/chakra-ui-theme-foundations-spacing.esm.js';
import '@chakra-ui/anatomy';
import '@chakra-ui/theme-tools';
import '../foundations/typography/dist/chakra-ui-theme-foundations-typography.esm.js';
import '@chakra-ui/system';
import '../foundations/borders/dist/chakra-ui-theme-foundations-borders.esm.js';
import '../foundations/breakpoints/dist/chakra-ui-theme-foundations-breakpoints.esm.js';
import '../foundations/colors/dist/chakra-ui-theme-foundations-colors.esm.js';
import '../foundations/radius/dist/chakra-ui-theme-foundations-radius.esm.js';
import '../foundations/shadows/dist/chakra-ui-theme-foundations-shadows.esm.js';
import '../foundations/transition/dist/chakra-ui-theme-foundations-transition.esm.js';
import '../foundations/z-index/dist/chakra-ui-theme-foundations-z-index.esm.js';
import '../foundations/blur/dist/chakra-ui-theme-foundations-blur.esm.js';

var semanticTokens = {
  colors: {
    "chakra-body-text": {
      _light: "gray.800",
      _dark: "whiteAlpha.900"
    },
    "chakra-body-bg": {
      _light: "white",
      _dark: "gray.800"
    },
    "chakra-border-color": {
      _light: "gray.200",
      _dark: "whiteAlpha.300"
    },
    "chakra-placeholder-color": {
      _light: "gray.500",
      _dark: "whiteAlpha.400"
    }
  }
};

var styles = {
  global: {
    body: {
      fontFamily: "body",
      color: "chakra-body-text",
      bg: "chakra-body-bg",
      transitionProperty: "background-color",
      transitionDuration: "normal",
      lineHeight: "base"
    },
    "*::placeholder": {
      color: "chakra-placeholder-color"
    },
    "*, *::before, &::after": {
      borderColor: "chakra-border-color",
      wordWrap: "break-word"
    }
  }
};
var styles$1 = styles;

var requiredChakraThemeKeys = ["borders", "breakpoints", "colors", "components", "config", "direction", "fonts", "fontSizes", "fontWeights", "letterSpacings", "lineHeights", "radii", "shadows", "sizes", "space", "styles", "transition", "zIndices"];
function isChakraTheme(unit) {
  if (!isObject(unit)) {
    return false;
  }

  return requiredChakraThemeKeys.every(function (propertyName) {
    return Object.prototype.hasOwnProperty.call(unit, propertyName);
  });
}

var direction = "ltr";
var config = {
  useSystemColorMode: false,
  initialColorMode: "light",
  cssVarPrefix: "chakra"
};
var theme = _extends({
  semanticTokens: semanticTokens,
  direction: direction
}, foundations, {
  components: components,
  styles: styles$1,
  config: config
});

export { theme as default, isChakraTheme, requiredChakraThemeKeys, theme };
